import image1 from '../../assets/home/beryl.jpg';
import image2 from '../../assets/home/esther.jpg';
import image3 from '../../assets/home/osala.jpg';
import image4 from '../../assets/home/lynn.jpg';
import image5 from '../../assets/home/Tony.jpg';
import image6 from '../../assets/home/manu.jpg';

export const fakeData = [
    {
        id: 1,
        img: image1,
        name: 'Beryl Odewo',
        profession: 'Software Engineer',
        message: "Mebiut’s mayonnaise is simply great! It has the perfect creaminess and flavor that elevates my sandwiches and salads. I never knew mayonnaise could taste this good. It has become a staple in my kitchen!"
    },
    {
        id: 2,
        img: image2,
        name: 'Esther Njenga',
        profession: 'Program Manager CMETrust',
        message: "I absolutely love the unique and real taste of Mebiut’s sauces! They add a delightful flavor to my dishes without being too spicy, making them perfect for my child. It’s great to have a condiment that everyone in the family enjoys. Highly recommend trying them!"
    },
    {
        id: 3,
        img: image3,
        name: 'Osallah',
        profession: 'Health Champion',
        message: "I’m really enjoying Mebiut’s sauces. They have transformed the way I cook and eat. Each sauce adds a distinct flavor to my meals, making them taste so much better. I can’t imagine cooking without them!"
    },
    {
        id: 4,
        img: image4,
        name: 'Lynn Akinyi',
        profession: 'Senior UI/UX Engineer',
        message: "Mebiut’s sauces are a game changer for me! They’re packed with flavor and have become a must-have in my kitchen. Whether I’m grilling or making a quick meal, I always reach for Mebiut. Absolutely love them!"
    },
    {
        id: 5,
        img: image5,
        name: 'Tony',
        profession: 'Economist',
        message: "The flavors in Mebiut’s sauces are incredible! They bring out the best in every dish I prepare. From marinades to dips, I can't get enough of them. They truly enhance my cooking experience!"
    },
    {
        id: 6,
        img: image6,
        name: 'Emmanuel Agwenyi',
        profession: 'Health Diets Champion',
        message: "I recently tried MEBIUT’s locally made mayonnaise and Kenyan Taste sauce, and it’s a game-changer! The creamy mayo pairs perfectly with the tangy Kenyan Taste sauce, enhancing any meal. True to their slogan, Make Every Bite Unforgettable, MEBIUT delivers an exceptional taste experience. Proudly Kenyan, this combo is a must-try!"
    },
]
