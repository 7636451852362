import img1 from '../../../assets/aboutUs/05.jpeg';
import img2 from '../../../assets/aboutUs/11.jpeg';

const fakeData = [
    {
        id: 1,
        heading: 'Kenyan Taste Sauce',
        img: img1,
        content: 'At Mebiut, our Kenyan Taste Sauces are crafted with the finest locally sourced spices and ingredients, bringing the authentic flavors of Kenya to your kitchen. Whether you want to spice up your meals or explore new culinary adventures, our sauces offer the perfect balance of heat and flavor. Each bottle is a tribute to Kenya’s rich culinary heritage, made with creativity and passion for good taste.'
    },
    {
        id: 2,
        heading: 'Kenyan Taste Mayonnaise',
        img: img2,
        content: 'Our Kenyan Taste Mayonnaise is the perfect blend of creamy richness and delicate flavor. Ideal for sandwiches, salads, and dipping, it is a versatile staple in any kitchen. Made with high-quality ingredients, this mayonnaise brings out the best in every dish. At Mebiut, we ensure that our products are crafted with care, delivering the perfect consistency and taste to enhance your meals.'
    },
]

export default fakeData;
