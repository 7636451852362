import img1 from '../../assets/caseStudy/k1.jpeg';
import img2 from '../../assets/caseStudy/case4.jpeg';
import img3 from '../../assets/caseStudy/k2.jpeg';
import img4 from '../../assets/caseStudy/k3.jpeg';
import img5 from '../../assets/caseStudy/case5.jpeg';
import img6 from '../../assets/caseStudy/case6.jpeg';

const fakeData = [
    {
        id: 1,
        title: 'Kenyan Taste',
        img: img1,
        category: 'kenyan taste'
    },
    {
        id: 2,
        title: 'Mayonnaise',
        img: img2,
        category: 'mayonnaise'
    },
    {
        id: 3,
        title: 'Kenyan Taste',
        img: img3,
        category: 'kenyan taste'
    },
    {
        id: 4,
        title: 'Kenyan Taste',
        img: img4,
        category: 'kenyan taste'
    },
    {
        id: 5,
        title: 'Kenyan Taste',
        img: img5,
        category: 'kenyan taste'
    },
    {
        id: 6,
        title: 'Kenyan Taste',
        img: img6,
        category: 'kenyan taste'
    },
    
];

export default fakeData;
