import { faUtensils, faTruck, faBoxOpen, faChalkboardTeacher, faStore } from '@fortawesome/free-solid-svg-icons';

const data = [
    {
        id: 1,
        heading: 'Custom Sauce Development',
        content: 'Collaborate with our team to create unique sauce flavors tailored to your culinary needs.',
        to: '/',
        icon: faUtensils,
    },
    {
        id: 2,
        heading: 'Subscription Service',
        content: 'Enjoy a curated selection of Mebiut sauces delivered to your doorstep every month, featuring exclusive flavors.',
        to: '/',
        icon: faTruck,
    },
    {
        id: 3,
        heading: 'Corporate and Bulk Orders',
        content: 'Effortlessly order large quantities of Mebiut sauces for your restaurant or event with our dedicated service.',
        to: '/',
        icon: faBoxOpen,
    },
    {
        id: 4,
        heading: 'Sauce Tastings and Workshops',
        content: 'Join our tastings and workshops to explore flavors and learn how to use our sauces in delicious recipes.',
        to: '/',
        icon: faChalkboardTeacher,
    },
    {
        id: 5,
        heading: 'Wholesale and Distribution',
        content: 'Partner with us to bring Mebiut sauces to your store or restaurant through our wholesale distribution service.',
        to: '/',
        icon: faStore,
    },
];

export default data;
